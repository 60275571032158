import React, { useContext } from "react"
import useIsClient from "../is-client-hook"
import H1 from "../unitary/h1"
import H1Small from "../unitary/h1-small"
import ResponsiveContext from "../../contexts/responsive-context"
import { getIconByKey } from "../helpers/icons"

export default function ResponsiveRowTitle(props) {
  const context = useContext(ResponsiveContext)
  const isClient = useIsClient().isClient

  const title = props.title
  const icon = getIconByKey(props.icon)

  if (!isClient) {
    return null
  }

  const iconColumn =
    icon && !context.isTouch ? (
      <div className="column is-3 is-flex is-align-items-center is-justify-content-center">
        <span className="icon">{icon}</span>
      </div>
    ) : null

  if (context.isMobile) {
    return <H1 title={title} />
  } else {
    return (
      <div className="columns is-flex-grow-1">
        {iconColumn}
        <div className="column my-2">
          <H1Small title={title} />
        </div>
      </div>
    )
  }
}
