import React, { useContext } from "react"
import ResponsiveContext from "../../contexts/responsive-context"
import TileChild from "../layout/tile-child"
import Content from "../unitary/content"
import H2 from "../unitary/h2"
import ResponsiveRowTitle from "./responsive-row-title"

export const FIRST_COLUMN_STYLES = Object.freeze({
  header: "header",
})

export const TABLE_ROW_BODY_HORIZONTAL_ALIGNMENTS = Object.freeze({
  centered: "centered",
})

export default function TableRow(props) {
  const context = useContext(ResponsiveContext)
  const isVerticallyAligned = props.isVerticallyAligned
  const headers = props.headers
  const childTiles = headers.map((header, index) => {
    const text = props.row.columns[index]
    let borderClass = headers.length === index + 1 ? "" : "opg-right-border"

    if (context.isMobile) {
      borderClass = ""
    }

    const isFirstColumn = index === 0

    const content =
      isFirstColumn && props.firstColumnStyle === FIRST_COLUMN_STYLES.header ? (
        <ResponsiveRowTitle title={text} icon={props.row.icon} />
      ) : (
        <Content body={text} classes={["mb-0"]} />
      )

    const mobileSubheader = context.isMobile ? (
      <TileChild classes={["has-text-centered"]}>
        <H2 title={header.title} />
      </TileChild>
    ) : null

    let childTileClasses = [`is-${header.width}`, "p-3", borderClass]

    if (isVerticallyAligned && !context.isMobile) {
      childTileClasses.push(...["is-flex", "is-align-items-center"])
    }

    if (
      isFirstColumn ||
      props.bodyHorizontalAlignment ===
        TABLE_ROW_BODY_HORIZONTAL_ALIGNMENTS.centered
    ) {
      childTileClasses.push("has-text-centered")
    }

    return (
      <div
        className={`${childTileClasses.join(" ")} tile is-child`}
        key={index}
      >
        <div className="is-flex-grow-1">
          {mobileSubheader}
          {content}
        </div>
      </div>
    )
  })

  return childTiles
}
