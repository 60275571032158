// https://stackoverflow.com/a/43769782/1450420
// Font awesome pixel sizes relative to the multiplier.
// 1x - 14px
// 2x - 28px
// 3x - 42px
// 4x - 56px
// 5x - 70px

// <FaFolderOpen size={70} />

import React from "react"
import {
  FaBolt,
  FaCouch,
  FaHammer,
  FaHeart,
  FaRss,
  FaSearch,
  FaSyncAlt,
  FaTv,
  FaUserFriends,
} from "react-icons/fa"

export function getIconByKey(key) {
  if (!key) {
    return
  }
  switch (key) {
    case "FaTv":
      return <FaTv size={28} />
    case "FaHeart":
      return <FaHeart size={28} />
    case "FaRss":
      return <FaRss size={28} />
    case "FaSyncAlt":
      return <FaSyncAlt size={28} />
    case "FaSearch":
      return <FaSearch size={28} />
    case "FaUserFriends":
      return <FaUserFriends size={28} />
    case "FaCouch":
      return <FaCouch size={28} />
    case "FaBolt":
      return <FaBolt size={28} />
    case "FaHammer":
      return <FaHammer size={28} />
    default:
      throw new Error(`Unknown icon: ${key}`)
  }
}
