import React, { useContext } from "react"
import useIsClient from "../is-client-hook"
import ResponsiveContext from "../../contexts/responsive-context"
import ChildMapper from "../child-mapper"
import { tableToothpasteRowRenderer } from "../child-mappable/table-toothpaste-row"
import Columns from "../layout/columns"
import H1Small from "../unitary/h1-small"

function CustomHeaderRow(props) {
  const context = useContext(ResponsiveContext)
  if (context.isMobile) {
    return null
  }

  const headers = props.data
  const columns = headers.map((header, index) => {
    return (
      <div key={index} className="column has-text-centered pb-0 is-flex">
        {index > 0 && (
          <div
            className="box"
            style={{
              borderRadius: "10px 10px 0px 0px",
              flexGrow: 1,
              margin: "0px 10px",
            }}
          >
            <H1Small title={header.title}></H1Small>
          </div>
        )}
      </div>
    )
  })

  return (
    <section className="section">
      <div className="columns ">
        <div className="column is-2" style={{ marginLeft: "-20px" }}></div>
        <div className="column">
          <div style={{ padding: "0px 20px" }}>
            <Columns>{columns}</Columns>
          </div>
        </div>
      </div>
    </section>
  )
}

export default function TableToothpaste(props) {
  const isClient = useIsClient().isClient

  if (!isClient) {
    return null
  }

  return (
    <div>
      <CustomHeaderRow data={props.data.headers} />
      <ChildMapper
        data={props.data.rows}
        render={tableToothpasteRowRenderer}
        options={{
          chevronColumnWidth: "is-2",
          tableHeaders: props.data.headers,
        }}
      />
    </div>
  )
}
