import React from "react"
import { H2_STYLE_CLASSES } from "../../constants"
import Chevron from "../unitary/chevron"
import Content from "../unitary/content"

export const chevronInColumnRenderer = ({
  index,
  data,
  images,
  heightRecorder,
  titleMinHeight,
  bodyMinHeight,
  isLastChild,
}) => (
  <ChevronInColumn
    key={index}
    listId={index}
    data={data}
    heightRecorder={heightRecorder}
    titleMinHeight={titleMinHeight}
    isLastChild={isLastChild}
  />
)

class ChevronInColumn extends React.Component {
  render() {
    return (
      <div className="column" style={{ paddingLeft: 0, paddingRight: 0 }}>
        <Chevron
          listId={this.props.listId}
          heightRecorder={this.props.heightRecorder}
          titleMinHeight={this.props.titleMinHeight}
          isLastChild={this.props.isLastChild}
        >
          <div style={{ paddingTop: 20, paddingBottom: 20, marginLeft: 10 }}>
            <Content body={this.props.data} classes={H2_STYLE_CLASSES} />
          </div>
        </Chevron>
      </div>
    )
  }
}
