// @ts-ignore
import yaml from "../../data/the-six-fundamental-marketing-strategies.yaml"
import React from "react"
import { validatePageYamlData } from "../../helpers/prop-types"
import Header from "../../components/sections/header"
import { ChevronIntroduction } from "../../components/sections/chevron-introduction"
import LayoutContainer from "../../components/layout/layout-container"
import TableToothpaste from "../../components/sections/table-toothpaste"
import { graphql } from "gatsby"
import { PortraitArrayWithTitle } from "../../components/sections/portrait-array-with-title"

export default function TheSixFundamentalMarketingStrategies(props) {
  validatePageYamlData(yaml)
  const images = props.data
  return (
    <LayoutContainer>
      <Header {...yaml.header} />
      <ChevronIntroduction data={yaml.introduction} />
      <TableToothpaste data={yaml.table} />
      <PortraitArrayWithTitle
        data={yaml.start_with_a_business_model}
        images={images}
      />
      <PortraitArrayWithTitle data={yaml.apply_to_all_brands} images={images} />
    </LayoutContainer>
  )
}

export const query = graphql`
  query {
    airbnb_logo: file(
      relativePath: { eq: "six-fundamental-strategies/airbnb_logo.png" }
    ) {
      ...fixedWidthFourColumns
    }
    macdonalds_logo: file(
      relativePath: { eq: "six-fundamental-strategies/macdonalds_logo.png" }
    ) {
      ...fixedWidthFourColumns
    }
    linkedin_logo: file(
      relativePath: { eq: "six-fundamental-strategies/linkedin_logo.png" }
    ) {
      ...fixedWidthFourColumns
    }
    avon_logo: file(
      relativePath: { eq: "six-fundamental-strategies/avon_logo.png" }
    ) {
      ...fixedWidthFourColumns
    }
    ollie_logo: file(
      relativePath: { eq: "six-fundamental-strategies/ollie_logo.png" }
    ) {
      ...fixedWidthFourColumns
    }
    olaplex_logo: file(
      relativePath: { eq: "six-fundamental-strategies/olaplex_logo.png" }
    ) {
      ...fixedWidthFourColumns
    }
    dollar_shave_club_logo: file(
      relativePath: {
        eq: "six-fundamental-strategies/dollar_shave_club_logo.png"
      }
    ) {
      ...fixedWidthFourColumns
    }
    colourpop_logo: file(
      relativePath: { eq: "six-fundamental-strategies/colourpop_logo.png" }
    ) {
      ...fixedWidthFourColumns
    }
    tigi_logo: file(
      relativePath: { eq: "six-fundamental-strategies/tigi_logo.png" }
    ) {
      ...fixedWidthFourColumns
    }
  }
`
