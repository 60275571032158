import React, { useContext } from "react"
import ResponsiveContext from "../../contexts/responsive-context"
import { chevronInColumnRenderer } from "../child-mappable/chevron-in-column"
import ChildMapper from "../child-mapper"
import { Section } from "./section"
import TitleWithUnderline from "../unitary/title-with-underline"
import Box from "../layout/box"
import useIsClient from "../is-client-hook"

// @ts-ignore
import {opgElementSpacing} from "../../styles/opg-variables.module.scss"


class BulletPoints extends React.Component {
  render() {
    const li = this.props.data.map((child, index) => {
      return <li key={index}>{child}</li>
    })
    return (
      <div className="column content">
        <ul>{li}</ul>
      </div>
    )
  }
}

export function ChevronIntroduction(props) {
  const context = useContext(ResponsiveContext)
  const key = useIsClient().key

  const desktopComponent = (
    <div
      className="columns"
      style={{
        paddingLeft: opgElementSpacing,
        paddingRight: opgElementSpacing,
      }}
    >
      <ChildMapper data={props.data.points} render={chevronInColumnRenderer} />
    </div>
  )
  const mobileComponent = (
    <Box>
      <TitleWithUnderline title={props.data.title} />
      <div className="columns">
        <BulletPoints data={props.data.points} />
      </div>
    </Box>
  )
  const component = context.isMobile ? mobileComponent : desktopComponent

  return <Section key={key}>{component}</Section>
}
