import React, { useContext } from "react"
import useIsClient from "../is-client-hook"
import Toothpaste from "../sections/toothpaste"
import ResponsiveContext from "../../contexts/responsive-context"
import TableRow from "../table/table-row"
import {
  extractChevronColumnWidth,
  extractTableHeaders,
} from "../helpers/extract-options"
import TileAncestor from "../layout/tile-ancestor"
import TileParent from "../layout/tile-parent"
import ResponsiveRowTitle from "../table/responsive-row-title"

export const tableToothpasteRowRenderer = ({
  index,
  data,
  images,
  heightRecorder,
  titleMinHeight,
  bodyMinHeight,
  options,
  isLastChild,
}) => (
  <TableToothpasteRow
    key={index}
    listId={index}
    data={data}
    heightRecorder={heightRecorder}
    titleMinHeight={titleMinHeight}
    options={options}
    isLastChild={isLastChild}
  />
)

function ResponsivePaddingY(props) {
  const context = useContext(ResponsiveContext)
  const isClient = useIsClient().isClient

  if (!isClient) {
    return null
  }

  const py = context.isMobile ? "" : "py-5"
  return <div className={py}>{props.children}</div>
}

function TableToothpasteRow(props) {
  const titleChild = (
    <ResponsivePaddingY>
      <ResponsiveRowTitle
        icon={props.data.row_external_header.icon}
        title={props.data.row_external_header.title}
      />
    </ResponsivePaddingY>
  )
  return (
    <Toothpaste
      heightRecorder={props.heightRecorder}
      chevronColumnWidth={extractChevronColumnWidth(props.options)}
      titleMinHeight={props.titleMinHeight}
      titleChild={titleChild}
    >
      <TileAncestor>
        <TileParent>
          <TableRow
            headers={extractTableHeaders(props.options)}
            row={props.data}
            isVerticallyAligned={true}
          />
        </TileParent>
      </TileAncestor>
    </Toothpaste>
  )
}
