import { VALID_COLUMN_WIDTHS } from "../../constants"

export function extractChevronColumnWidth(options) {
  const width = options && options.chevronColumnWidth
  if (width) {
    if (!VALID_COLUMN_WIDTHS.includes(width)) {
      throw new Error(`Unknown columnWidth: ${width}`)
    } else {
      return width
    }
  } else {
    return null
  }
}

export function extractTableHeaders(options) {
  return options && options.tableHeaders
}
